{
  "preset": "Default",
  "remembered": {
    "Default": {},
    "Coral": {
      "0": {
        "feed": 55,
        "kill": 62
      }
    },
    "Dots": {
      "0": {
        "feed": 30,
        "kill": 62
      }
    },
    "Fireflies": {
      "0": {
        "feed": 7,
        "kill": 47
      }
    },
    "Gliders": {
      "0": {
        "feed": 1,
        "kill": 37
      }
    },
    "Holes": {
      "0": {
        "feed": 39,
        "kill": 58
      }
    },
    "Hypnosis": {
      "0": {
        "feed": 20,
        "kill": 45
      }
    },
    "Mercury": {
      "0": {
        "feed": 26,
        "kill": 50
      }
    },
    "Raindrops": {
      "0": {
        "feed": 12,
        "kill": 48
      }
    },
    "Raindrops 2": {
      "0": {
        "feed": 12,
        "kill": 53
      }
    },
    "Raindrops 3": {
      "0": {
        "feed": 18,
        "kill": 53
      }
    },
    "Shimmer": {
      "0": {
        "feed": 23,
        "kill": 51
      }
    },
    "Spirals": {
      "0": {
        "feed": 11,
        "kill": 41
      }
    },
    "Spirals 2": {
      "0": {
        "feed": 7,
        "kill": 43
      }
    },
    "Spirals 3": {
      "0": {
        "feed": 5,
        "kill": 25
      }
    },
    "Trippy 1": {
      "0": {
        "feed": 20,
        "kill": 50,
        "renderer": "psychedelic"
      }
    },
    "Trippy 2": {
      "0": {
        "feed": 15,
        "kill": 41,
        "renderer": "psychedelic"
      }
    },
    "Trippy 3": {
      "0": {
        "feed": 25,
        "kill": 51,
        "renderer": "psychedelic"
      }
    },
    "Trippy 4": {
      "0": {
        "feed": 34,
        "kill": 56,
        "renderer": "threshold",
        "threshold": 0.4
      }
    },
    "Trippy 5": {
      "0": {
        "feed": 34,
        "kill": 55,
        "renderer": "threshold",
        "threshold": 0.4
      }
    },
    "Trippy 6": {
      "0": {
        "feed": 16,
        "kill": 42,
        "renderer": "threshold",
        "threshold": 0.4
      }
    },
    "Vines": {
      "0": {
        "feed": 39,
        "kill": 63
      }
    }
  }
}
